import React, { useRef, useState, useEffect } from "react";
import Axios from "axios";
import QRCode from "qrcode.react";
import styled from "styled-components";

import {
  InputBox,
  ModalLabel,
  InputFieldModal,
  FormContainer,
  Form,
  DataValue,
  BtnLarge,
} from "./CommonStylesForms";

const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`;

const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  font-size: 30px;
`;

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  background: #fff;
`;

function TravelerForm() {
  const [invitation, setInvitation] = useState({});
  const [connectionConfirmed, setConnectionConfirmed] = useState(false);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [trustedCredIssued, setTrustedCredIssued] = useState(false);
  const [verification, setVerification] = useState({});
  const [errMessage, setErrMessage] = useState("");
  const [toggleForm, setToggleForm] = useState(false);
  const [travelDetails, setTravelDetails] = useState({});
  const [verifiedImage, setVerifiedImage] = useState("");

  const effectRan = useRef();
  const newForm = useRef();

  const conController = new AbortController();
  const verController = new AbortController();

  const handleError = (error) => {
    if (error.response && error.response.data && error.response.data.message) {
      setErrMessage(error.response.data.message);
    } else if (error.name === "CanceledError") {
      console.log("Request canceled!");
    } else {
      console.error("Error: ", error);
    }
  };

  const requestInvitation = () => {
    if (Object.keys(invitation).length === 0) {
      Axios({
        method: "POST",
        url: `/api/invitations`,
      })
        .then((res) => {
          console.log("Invitation:", res.data);

          setInvitation(res.data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  useEffect(() => {
    //(AmmonBurgi) Prevent from triggering twice in development. See React strict mode.
    if (process.env.NODE_ENV === "development") {
      if (effectRan.current === true) {
        requestInvitation();
      }

      return () => {
        effectRan.current = true;
      };
    } else {
      requestInvitation();
    }
  }, [invitation]);

  useEffect(() => {
    if (invitation.connection_id && !connectionConfirmed) {
      Axios({
        method: "GET",
        url: `/api/connections/${invitation.connection_id}`,
        timeout: 1000 * 60 * 35,
        signal: conController.signal,
      })
        .then(() => {
          console.log("Connection confirmed!");
          setConnectionConfirmed(true);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [invitation, connectionConfirmed]);

  useEffect(() => {
    if (
      invitation.invitation_id &&
      connectionConfirmed &&
      !verificationComplete
    ) {
      Axios({
        method: "POST",
        url: `/api/verifications`,
        data: {
          connection_id: invitation.connection_id,
          contact_id: invitation.contact_id,
          invitation_id: invitation.invitation_id,
        },
        timeout: 1000 * 60 * 35,
        signal: verController.signal,
      })
        .then((verRes) => {
          const verificationRecords = verRes.data.verificationRecords;
          setVerificationComplete(true);

          console.log("Verification:", verRes);

          let verifiedAttributes = {};
          verificationRecords.forEach((record) => {
            record.result_data.forEach((attributes, index) => {
              verifiedAttributes[attributes.name] = attributes.value;
            });
          });

          //(AmmonBurgi) If needed, format the base64 to be compatible with an html img element
          if (verifiedAttributes["chip-photo"]) {
            const imageParts = verifiedAttributes["chip-photo"].split(",");
            if (imageParts[0] !== "data:image/jpeg;base64") {
              setVerifiedImage(
                "data:image/jpeg;base64," + verifiedAttributes["chip-photo"]
              );
            } else {
              setVerifiedImage(verifiedAttributes["chip-photo"]);
            }
          }

          setVerification({
            connectionId: verificationRecords[0].connection_id,
            verifiedAttributes: verifiedAttributes,
          });
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [invitation, connectionConfirmed, verificationComplete]);

  const resetToInvitation = () => {
    //(AmmonBurgi) Abort any pending requests, to avoid invalid state changes
    conController.abort();
    verController.abort();

    setInvitation("");
    setConnectionConfirmed(false);
    setVerificationComplete(false);
    setToggleForm(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(newForm.current);
    const formData = {
      traveler_phone: form.get("traveler_phone"),
      passenger_image: verification.verifiedAttributes["chip-photo"],
      traveler_email: form.get("traveler_email"),
      traveler_country: form.get("traveler_country"),
      traveler_country_of_origin: form.get("traveler_country_of_origin"),
      arrival_airline: form.get("arrival_airline"),
      arrival_flight_number: form.get("arrival_flight_number"),
      arrival_date: form.get("arrival_date"),
      arrival_destination_port_code: form.get("arrival_destination_port_code"),
      arrival_destination_country_code: form.get(
        "arrival_destination_country_code"
      ),
      departure_airline: form.get("departure_airline"),
      departure_flight_number: form.get("departure_flight_number"),
      departure_date: form.get("departure_date"),
      departure_destination_port_code: form.get(
        "departure_destination_port_code"
      ),
      departure_destination_country_code: form.get(
        "departure_destination_country_code"
      ),
    };

    console.log(verification.connectionId);

    Axios({
      method: "POST",
      url: `/api/credentials/`,
      data: {
        travelerData: formData,
        dtcData: verification.verifiedAttributes,
        contact_id: "",
        invitation_id: invitation.invitation_id,
      },
    })
      .then(() => {
        setTrustedCredIssued(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const selectOption = (option) => {
    switch (option) {
      case "option_one":
        console.log(option);
        setTravelDetails({
          traveler_phone: "801-123-4675",
          traveler_email: "example@example.com",
          origin_port_code: "JFK",
          traveler_country: "USA",
          traveler_country_of_origin: "USA",
          arrival_airline: "SITA Air",
          arrival_flight_number: "XS 123",
          arrival_date: "2022-11-07",
          arrival_destination_port_code: "AUA",
          arrival_destination_country_code: "AW",
          departure_airline: "SITA Air",
          departure_flight_number: "XS 321",
          departure_date: "2022-11-14",
          departure_destination_port_code: "JFK",
          departure_destination_country_code: "USA",
        });

        break;

      case "option_two":
        console.log(option);
        setTravelDetails({
          traveler_phone: "987-654-4434",
          traveler_email: "example@example.com",
          origin_port_code: "LHR",
          traveler_country: "UK",
          traveler_country_of_origin: "UK",
          arrival_airline: "Air SITA",
          arrival_flight_number: "XS 432",
          arrival_date: "2022-12-07",
          arrival_destination_port_code: "CDG",
          arrival_destination_country_code: "FR",
          departure_airline: "Air SITA",
          departure_flight_number: "XS 234",
          departure_date: "2022-12-16",
          departure_destination_port_code: "LHR",
          departure_destination_country_code: "UK",
        });

        break;

      case "option_three":
        console.log(option);
        setTravelDetails({
          traveler_phone: "123-455-6654",
          traveler_email: "example@example.com",
          origin_port_code: "SYD",
          traveler_country: "AU",
          traveler_country_of_origin: "AU",
          arrival_airline: "Fly SITA",
          arrival_flight_number: "XS 875",
          arrival_date: "2023-01-11",
          arrival_destination_port_code: "SIN",
          arrival_destination_country_code: "SG",
          departure_airline: "Fly SITA",
          departure_flight_number: "XS 578",
          departure_date: "2023-01-13",
          departure_destination_port_code: "SYD",
          departure_destination_country_code: "AU",
        });

        break;

      default:
        setTravelDetails({
          traveler_phone: "",
          traveler_email: "",
          origin_port_code: "",
          traveler_country: "",
          traveler_country_of_origin: "",
          arrival_airline: "",
          arrival_flight_number: "",
          arrival_date: "",
          arrival_destination_port_code: "",
          arrival_destination_country_code: "",
          departure_airline: "",
          departure_flight_number: "",
          departure_date: "",
          departure_destination_port_code: "",
          departure_destination_country_code: "",
        });
        break;
    }
  };

  let attributes = verification.verifiedAttributes
    ? verification.verifiedAttributes
    : {};

  const passportDisplay = (
    <div>
      <div className="plane-row">
        <div className="one-third-column">
          <div className="title-center-text">Submit Passport Details</div>
        </div>
        <div className="two-third-column"></div>
      </div>
      <div className="bar-row">
        <span className="bold-bar-text">Review:</span>
        <span className="bar-text">
          Travelers receive confirmation that their DTC credential details were
          successfully received by the system
        </span>
      </div>
      <div className="content-row">
        <div className="one-third-column">
          <div className="content-text">
            Thank you for sharing your DTC credential with us. Here is the
            information we received.
          </div>
          <p className="spacing">
            <BtnLarge onClick={() => setToggleForm(true)}>Continue →</BtnLarge>
          </p>
        </div>
        <div className="two-third-column">
          <div className="passport-div">
            {verifiedImage ? (
              <img className="chip-photo" src={verifiedImage} alt="Passport" />
            ) : null}
            <span className="type">
              {attributes["document-type"] ? attributes["document-type"] : ""}
            </span>
            <span className="country-code">UTO</span>
            <span className="passport-number">
              {attributes["document-number"]
                ? attributes["document-number"]
                : ""}
            </span>
            <span className="surname">
              {attributes["family-name"] ? attributes["family-name"] : ""}
            </span>
            <span className="given-names">
              {attributes["given-names"] ? attributes["given-names"] : ""}
            </span>
            <span className="nationality">
              {attributes.nationality ? attributes.nationality : ""}
            </span>
            <span className="birth-date">
              {attributes["date-of-birth"] ? attributes["date-of-birth"] : ""}
            </span>
            <span className="sex">
              {attributes.gender ? attributes.gender : ""}
            </span>
            <span className="birth-place">Zenith</span>
            <span className="issue-date">
              {attributes["issue-date"] ? attributes["issue-date"] : ""}
            </span>
            <span className="authority">
              {attributes["issuing-authority"]
                ? attributes["issuing-authority"]
                : ""}
            </span>
            <span className="expiry-date">
              {attributes["expiry-date"] ? attributes["expiry-date"] : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const formDisplay = (
    <div>
      <div className="plane-row">
        <div className="one-third-column">
          <div className="title-center-text">Travel Details</div>
        </div>
        <div className="two-third-column"></div>
      </div>
      <div className="bar-row">
        <span className="bold-bar-text">Provide Additional Information:</span>
        <span className="bar-text">
          Travelers supply details about their trip that are not contained in
          their DTC credential
        </span>
      </div>
      <div className="content-row content-text">
        <div className="one-third-column">
          <p>Input your traveler information and tap Send to proceed</p>
        </div>
        <div className="two-third-column">
          <FormContainer>
            <FormWrapper>
              <HeaderVerify>Passport Details</HeaderVerify>
            </FormWrapper>
            <Form>
              <InputBox>
                <ModalLabel>Type</ModalLabel>
                <DataValue>
                  {attributes["document-type"]
                    ? attributes["document-type"]
                    : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Country Code</ModalLabel>
                <DataValue>UTO</DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Passport Number</ModalLabel>
                <DataValue>
                  {attributes["document-number"]
                    ? attributes["document-number"]
                    : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Surname</ModalLabel>
                <DataValue>
                  {attributes["family-name"] ? attributes["family-name"] : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Given Names</ModalLabel>
                <DataValue>
                  {attributes["given-names"] ? attributes["given-names"] : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Nationality</ModalLabel>
                <DataValue>
                  {attributes.nationality ? attributes.nationality : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Date of Birth</ModalLabel>
                <DataValue>
                  {attributes["date-of-birth"]
                    ? attributes["date-of-birth"]
                    : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Sex</ModalLabel>
                <DataValue>
                  {attributes.gender ? attributes.gender : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Place of Birth</ModalLabel>
                <DataValue>Zenith</DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Issue Date</ModalLabel>
                <DataValue>
                  {attributes["issue-date"] ? attributes["issue-date"] : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Authority</ModalLabel>
                <DataValue>
                  {attributes["issuing-authority"]
                    ? attributes["issuing-authority"]
                    : ""}
                </DataValue>
              </InputBox>
              <InputBox>
                <ModalLabel>Expiry Date</ModalLabel>
                <DataValue>
                  {attributes["expiry-date"] ? attributes["expiry-date"] : ""}
                </DataValue>
              </InputBox>
            </Form>
          </FormContainer>

          <Form id="form" onSubmit={handleSubmit} ref={newForm}>
            <InputBox>
              <ModalLabel>Travel Details Sample Sets</ModalLabel>
              <select
                onChange={(e) => selectOption(e.target.value)}
                className="dropdown-content"
              >
                <option value="default">Select Option:</option>
                <option value="option_one">Aruba</option>
                <option value="option_two">France</option>
                <option value="option_three">Singapore</option>
              </select>
            </InputBox>
            <FormContainer>
              <FormWrapper>
                <HeaderVerify>Travel Details</HeaderVerify>
              </FormWrapper>

              <InputBox>
                <ModalLabel htmlFor="traveler_phone">Phone</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="traveler_phone"
                  placeholder="123-456-7890"
                  defaultValue={travelDetails.traveler_phone}
                ></InputFieldModal>
              </InputBox>

              <InputBox>
                <ModalLabel htmlFor="traveler_email">Email</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="traveler_email"
                  placeholder="example@example.com"
                  defaultValue={travelDetails.traveler_email}
                ></InputFieldModal>
              </InputBox>

              <InputBox>
                <ModalLabel>Hotel</ModalLabel>
                <select>
                  <option>Select Hotel:</option>
                  <option>Inviting Inn</option>
                  <option>Happy Hotel</option>
                  <option>Moon Motel</option>
                </select>
              </InputBox>

              <FormWrapper>
                <HeaderVerify>Entry Information</HeaderVerify>
              </FormWrapper>

              <InputBox>
                <ModalLabel htmlFor="origin_port_code">
                  Origin Port Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="origin_port_code"
                  defaultValue={travelDetails.origin_port_code}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="traveler_country_of_origin">
                  Origin Country Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="traveler_country_of_origin"
                  defaultValue={travelDetails.traveler_country_of_origin}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="arrival_airline">
                  Arrival Airline
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="arrival_airline"
                  defaultValue={travelDetails.arrival_airline}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="arrival_flight_number">
                  Arrival Flight Number
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="arrival_flight_number"
                  defaultValue={travelDetails.arrival_flight_number}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="arrival_date">Arrival Date</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="arrival_date"
                  defaultValue={travelDetails.arrival_date}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="arrival_destination_port_code">
                  Arrival Port Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="arrival_destination_port_code"
                  defaultValue={travelDetails.arrival_destination_port_code}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="arrival_destination_country_code">
                  Arrival Country Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="arrival_destination_country_code"
                  defaultValue={travelDetails.arrival_destination_country_code}
                ></InputFieldModal>
              </InputBox>

              <FormWrapper>
                <HeaderVerify>Exit Information</HeaderVerify>
              </FormWrapper>

              <InputBox>
                <ModalLabel htmlFor="departure_airline">
                  Departure Airline
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="departure_airline"
                  defaultValue={travelDetails.departure_airline}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="departure_flight_number">
                  Departure Flight Number
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="departure_flight_number"
                  defaultValue={travelDetails.departure_flight_number}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="departure_date">Departure Date</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="departure_date"
                  defaultValue={travelDetails.departure_date}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="departure_destination_port_code">
                  Departure Port Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="departure_destination_port_code"
                  defaultValue={travelDetails.departure_destination_port_code}
                ></InputFieldModal>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="departure_destination_country_code">
                  Departure Country Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="departure_destination_country_code"
                  defaultValue={
                    travelDetails.departure_destination_country_code
                  }
                ></InputFieldModal>
              </InputBox>
            </FormContainer>
            <p className="text-center">
              <BtnLarge type="submit">Send →</BtnLarge>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );

  const successDisplay = (
    <div>
      <div className="plane-row">
        <div className="one-third-column">
          <div className="title-center-text">Success!</div>
        </div>
        <div className="two-third-column"></div>
      </div>
      <div className="content-row">
        <div className="one-third-column">
          <div className="content-text">
            <p>Thank you, your ETA is in process for approval.</p>
          </div>
          <div className="content-text">
            If approved, you should receive a trusted traveler credential offer
            on your mobile device shortly.
          </div>
        </div>
      </div>
    </div>
  );

  const invitationDisplay = (
    <div>
      <div className="plane-row">
        <div className="two-third-column">
          <div className="title-text">
            Welcome to the Advanced
            <br />
            Travel Authorization System
          </div>
          <div className="title-explain-text">
            Seamless travel that combines passport, travel, and customs
            <br />
            information in a privacy-preserving, easy-to-use workflow for
            <br />
            travelers around the world
          </div>
        </div>
        <div className="one-third-column"></div>
      </div>
      <div className="bar-row">
        <span className="bold-bar-text">Download and Passport Scan:</span>
        <span className="bar-text">
          Travelers must have already downloaded the traveler mobile application
          onto their mobile device and received a DTC passport&nbsp;credential
        </span>
      </div>
      <div className="content-row">
        <div className="one-third-column">
          <div className="content-text">
            Using the mobile app, open the camera and scan the QR code presented
            here:
          </div>
        </div>
        <div className="one-third-column">
          <p>
            <QR value={invitation.invitation_url} size={350} renderAs="svg" />
          </p>
        </div>
        <div className="one-third-column"></div>
      </div>
    </div>
  );

  const verificationDisplay = (
    <div>
      <div className="plane-row">
        <div className="two-third-column">
          <div className="title-text">
            Welcome to the Advanced
            <br />
            Travel Authorization System
          </div>
          <div className="title-explain-text">
            Seamless travel that combines passport, travel, and customs
            <br />
            information in a privacy-preserving, easy-to-use workflow for
            <br />
            travelers around the world
          </div>
        </div>
        <div className="one-third-column"></div>
      </div>
      <div className="bar-row">
        <span className="bold-bar-text">Download and Passport Scan:</span>
        <span className="bar-text">
          Travelers must have already downloaded the traveler mobile application
          onto their mobile device and received a DTC passport&nbsp;credential
        </span>
      </div>
      <div className="content-row">
        <div className="one-third-column">
          <div className="content-text">
            The connection is being processed. Credentials must be presented and
            verified before proceeding:
          </div>
        </div>
        <div className="one-third-column">
          <div className="loader-wrapper">
            <span className="loader"></span>
          </div>
        </div>
        <div className="one-third-position">
          <button onClick={() => resetToInvitation()} className="reset-btn">
            Go back
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {!errMessage ? (
        invitation && invitation.invitation_url && connectionConfirmed ? (
          verificationComplete ? (
            !toggleForm ? (
              passportDisplay
            ) : !trustedCredIssued ? (
              formDisplay
            ) : (
              successDisplay
            )
          ) : (
            verificationDisplay
          )
        ) : (
          invitationDisplay
        )
      ) : (
        <div>
          <div className="plane-row">
            <div className="one-third-column">
              <div className="title-center-text">System Error</div>
            </div>
            <div className="two-third-column"></div>
          </div>
          <div className="content-row">
            <div className="one-third-column">
              <div className="content-text">
                <p>We're sorry, but there was an error with your submission.</p>
              </div>
              <div className="content-text">
                <p>
                  You can still travel, but you will need to visit the airline
                  desk at the airport.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TravelerForm;
